import {
  APP_BASE_HREF,
  CommonModule,
  DatePipe,
  PlatformLocation,
} from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { customerFilter, SplitPipe } from '../_pipes/filter.pipe';
import { ReplaceUnderscorePipe } from '../_pipes/replace-underscore-pipe';
import { SafeHtmlPipe } from '../safehtml.pipe';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { CustomerNavComponent } from './components/customer-nav/customer-nav.component';
import { DataAnalysisNavComponent } from './components/data-analysis-nav/data-analysis-nav.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { SelectAllCheckboxComponent } from './components/select-all-checkbox/select-all-checkbox.component';
import { SelectAllMatCheckboxComponent } from './components/select-all-mat-checkbox/select-all-mat-checkbox.component';
import { SharedRoutingModule } from './shared-routing.module';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GenerateUserPasswordModalComponent } from './components/generate-user-password-modal/generate-user-password-modal.component';
import { UpdateDocumentModalComponent } from './components/update-document-modal/update-document-modal.component';
import { DocumentRequestModalComponent } from './components/document-request-modal/document-request-modal.component';
import { DocumentUploadModalComponent } from './components/document-upload-modal/document-upload-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeadFormComponent } from './components/lead-form/lead-form.component';
import { AnimalFormComponent } from './components/lead-form/animal-form/animal-form.component';
import { BuildingFormComponent } from './components/lead-form/building-form/building-form.component';
import { CentralisationFormComponent } from './components/lead-form/centralisation-form/centralisation-form.component';
import { ConstructionFormComponent } from './components/lead-form/construction-form/construction-form.component';
import { HealthFormComponent } from './components/lead-form/health-form/health-form.component';
import { LegalProtectionFormComponent } from './components/lead-form/legal-protection-form/legal-protection-form.component';
import { RcHouseholdFormComponent } from './components/lead-form/rc-household-form/rc-household-form.component';
import { ThirdPillarFormComponent } from './components/lead-form/third-pillar-form/third-pillar-form.component';
import { TravelFormComponent } from './components/lead-form/travel-form/travel-form.component';
import { ValuableObjectsFormComponent } from './components/lead-form/valuable-objects-form/valuable-objects-form.component';
import { VehicleFormComponent } from './components/lead-form/vehicle-form/vehicle-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CancellationFormComponent } from './components/lead-form/cancellation-form/cancellation-form.component';
import { CommissionBaseListComponent } from './components/commission-base-list/commission-base-list.component';
import { AssignProductsModalComponent } from './components/assign-products-modal/assign-products-modal.component';
import { AgGridModule } from 'ag-grid-angular';
import { DuplicateLeadComponent } from './components/duplicate-lead/duplicate-lead.component';
import { IconsModule } from './icons/icons.module';
import { LinearStepperComponent } from './components/linear-stepper/linear-stepper.component';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { ActionModalComponent } from './components/action-modal/action-modal.component';
import { TabNavigationComponent } from './components/tab-navigation/tab-navigation.component';
import { SlideInModalComponent } from './components/slide-in-modal/slide-in-modal.component';
import { FileSizePipe } from '../_pipes/file-size.pipe';
import { DonutChartComponent } from './components/charts/donut-chart/donut-chart.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { GridCopyButtonComponent } from './components/grid-copy-button/grid-copy-button.component';
import { DateTimePipe } from '../_pipes/date-time.pipe';
import { AddLeadModalComponent } from './components/add-lead-modal/add-lead-modal.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { ButtonLeadComponent } from './components/tasks-list/button-lead/button-lead.component';
import { MatMenuModule } from '@angular/material/menu';
import { LeadDetailsComponent } from './components/lead-details/lead-details.component';
import { CancellationMetadataComponent } from './components/lead-details/metadata-components/cancellation-metadata/cancellation-metadata.component';
import { ThirdPillarMetadataComponent } from './components/lead-details/metadata-components/third-pillar-metadata/third-pillar-metadata.component';
import { ValuableObjectsMetadataComponent } from './components/lead-details/metadata-components/valuable-objects-metadata/valuable-objects-metadata.component';
import { ConstructionMetadataComponent } from './components/lead-details/metadata-components/construction-metadata/construction-metadata.component';
import { VehicleMetadataComponent } from './components/lead-details/metadata-components/vehicle-metadata/vehicle-metadata.component';
import { HouseholdMetadataComponent } from './components/lead-details/metadata-components/household-metadata/household-metadata.component';
import { LegalProtectionMetadataComponent } from './components/lead-details/metadata-components/legal-protection-metadata/legal-protection-metadata.component';
import { AnimalMetadataComponent } from './components/lead-details/metadata-components/animal-metadata/animal-metadata.component';
import { BuildingMetadataComponent } from './components/lead-details/metadata-components/building-metadata/building-metadata.component';
import { TravelMetadataComponent } from './components/lead-details/metadata-components/travel-metadata/travel-metadata.component';
import { HealthMetadataComponent } from './components/lead-details/metadata-components/health-metadata/health-metadata.component';
import { CentralisationMetadataComponent } from './components/lead-details/metadata-components/centralisation-metadata/centralisation-metadata.component';
import { AddProposalModalComponent } from './components/add-proposal-modal/add-proposal-modal.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TagInputModule } from 'ngx-chips';
import { ViewProposalsComponent } from './components/view-proposals/view-proposals.component';
import { ProposalDetailsComponent } from './components/proposal-details/proposal-details.component';
import { ClaimDetailsComponent } from './components/claim-details/claim-details.component';
import { FloatingButtonsComponent } from './components/floating-buttons/floating-buttons.component';
import { AgentActivationModalComponent } from './components/agent-activation-modal/agent-activation-modal.component';
import { CommissionListButtonComponent } from './components/commission-base-list/commission-list-button/commission-list-button.component';
import { AddClaimModalComponent } from './components/add-claim-modal/add-claim-modal.component';
import { AnimalClaimInfoComponent } from './components/add-claim-modal/animal-claim-info/animal-claim-info.component';
import { BuildingClaimInfoComponent } from './components/add-claim-modal/building-claim-info/building-claim-info.component';
import { ClaimCircumstancesComponent } from './components/add-claim-modal/components/claim-circumstances/claim-circumstances.component';
import { ConstructionClaimInfoComponent } from './components/add-claim-modal/construction-claim-info/construction-claim-info.component';
import { HouseholdClaimInfoComponent } from './components/add-claim-modal/household-claim-info/household-claim-info.component';
import { LegalProtectionClaimInfoComponent } from './components/add-claim-modal/legal-protection-claim-info/legal-protection-claim-info.component';
import { TravelClaimInfoComponent } from './components/add-claim-modal/travel-claim-info/travel-claim-info.component';
import { VehicleClaimInfoComponent } from './components/add-claim-modal/vehicle-claim-info/vehicle-claim-info.component';
import { ValuableObjectsClaimInfoComponent } from './components/add-claim-modal/valuable-objects-claim-info/valuable-objects-claim-info.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { SlideUpModalComponent } from './components/slide-up-modal/slide-up-modal.component';
import { StackedTextRendererComponent } from './components/ag-grid-renderers/stacked-text-renderer/stacked-text-renderer.component';
import { StackedDateRendererComponent } from './components/ag-grid-renderers/stacked-date-renderer/stacked-date-renderer.component';
import { IconRendererComponent } from './components/ag-grid-renderers/icon-renderer/icon-renderer.component';
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    SelectAllCheckboxComponent,
    FileDragDropDirective,
    SafeHtmlPipe,
    customerFilter,
    SplitPipe,
    ReplaceUnderscorePipe,
    LanguageSwitcherComponent,
    CustomerNavComponent,
    DataAnalysisNavComponent,
    SelectAllMatCheckboxComponent,
    PdfViewerModalComponent,
    GenerateUserPasswordModalComponent,
    UpdateDocumentModalComponent,
    DocumentRequestModalComponent,
    DocumentUploadModalComponent,
    AddLeadModalComponent,
    LeadFormComponent,
    HealthFormComponent,
    TravelFormComponent,
    CentralisationFormComponent,
    RcHouseholdFormComponent,
    VehicleFormComponent,
    LegalProtectionFormComponent,
    BuildingFormComponent,
    ConstructionFormComponent,
    ThirdPillarFormComponent,
    AnimalFormComponent,
    ValuableObjectsFormComponent,
    CancellationFormComponent,
    CommissionBaseListComponent,
    AssignProductsModalComponent,
    DuplicateLeadComponent,
    LinearStepperComponent,
    BaseLayoutComponent,
    ActionModalComponent,
    TabNavigationComponent,
    SlideInModalComponent,
    FileSizePipe,
    DonutChartComponent,
    BarChartComponent,
    GridCopyButtonComponent,
    DateTimePipe,
    DuplicateLeadComponent,
    TasksListComponent,
    ButtonLeadComponent,
    LeadDetailsComponent,
    CancellationMetadataComponent,
    ThirdPillarMetadataComponent,
    ValuableObjectsMetadataComponent,
    ConstructionMetadataComponent,
    VehicleMetadataComponent,
    HouseholdMetadataComponent,
    LegalProtectionMetadataComponent,
    AnimalMetadataComponent,
    BuildingMetadataComponent,
    TravelMetadataComponent,
    HealthMetadataComponent,
    CentralisationMetadataComponent,
    AddProposalModalComponent,
    ViewProposalsComponent,
    ProposalDetailsComponent,
    ClaimDetailsComponent,
    FloatingButtonsComponent,
    AgentActivationModalComponent,
    CommissionListButtonComponent,
    AddClaimModalComponent,
    AnimalClaimInfoComponent,
    BuildingClaimInfoComponent,
    ClaimCircumstancesComponent,
    ConstructionClaimInfoComponent,
    HouseholdClaimInfoComponent,
    LegalProtectionClaimInfoComponent,
    TravelClaimInfoComponent,
    ValuableObjectsClaimInfoComponent,
    VehicleClaimInfoComponent,
    ToggleComponent,
    SlideUpModalComponent,
    StackedTextRendererComponent,
    StackedDateRendererComponent,
    IconRendererComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MatSelectModule,
    MatCheckboxModule,
    PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    AgGridModule,
    IconsModule,
    MatMenuModule,
    EditorModule,
    TagInputModule
  ],
  exports: [
    SelectAllCheckboxComponent,
    FileDragDropDirective,
    SafeHtmlPipe,
    LanguageSwitcherComponent,
    CustomerNavComponent,
    DataAnalysisNavComponent,
    ReplaceUnderscorePipe,
    SelectAllMatCheckboxComponent,
    customerFilter,
    SplitPipe,
    PdfViewerModalComponent,
    GenerateUserPasswordModalComponent,
    UpdateDocumentModalComponent,
    DocumentRequestModalComponent,
    DocumentUploadModalComponent,
    AddLeadModalComponent,
    CommissionBaseListComponent,
    DuplicateLeadComponent,
    IconsModule,
    LinearStepperComponent,
    BaseLayoutComponent,
    ActionModalComponent,
    TabNavigationComponent,
    SlideInModalComponent,
    FileSizePipe,
    DonutChartComponent,
    BarChartComponent,
    GridCopyButtonComponent,
    DateTimePipe,
    DuplicateLeadComponent,
    TasksListComponent,
    ButtonLeadComponent,
    LeadDetailsComponent,
    CancellationMetadataComponent,
    ThirdPillarMetadataComponent,
    ValuableObjectsMetadataComponent,
    ConstructionMetadataComponent,
    VehicleMetadataComponent,
    HouseholdMetadataComponent,
    LegalProtectionMetadataComponent,
    AnimalMetadataComponent,
    BuildingMetadataComponent,
    TravelMetadataComponent,
    HealthMetadataComponent,
    CentralisationMetadataComponent,
    AddProposalModalComponent,
    ViewProposalsComponent,
    ClaimDetailsComponent,
    FloatingButtonsComponent,
    AgentActivationModalComponent,
    AddClaimModalComponent,
    ToggleComponent,
    SlideUpModalComponent
  ],
  providers: [
    DatePipe,
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
  ],
})
export class SharedModule {}
