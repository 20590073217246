import { Component, AfterViewInit, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from '../../../globals';
import { RoleService } from 'src/app/_services/role.service';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';
import { ProfileService } from 'src/app/_services/profile.service';
import { NewsletterService } from 'src/app/_services/newsletter.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Partner } from 'src/app/_types/partner.type';
import { Notification } from 'src/app/_types/notifications.types';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements AfterViewInit {
  currentUrl: any = '';
  user: any;

  notificationList: Notification[] = [];
  totalNotification = 0;
  totalUnreadNotification = 0;

  private _navbarOpen = false;

  get navbarOpen(){
    return this._navbarOpen
  }

  set navbarOpen(value: boolean){
    this._navbarOpen = value
    if(value){
      document.body.style.overflow = "hidden";
    } else{
      document.body.style.overflow = "auto"
    }
  }

  isMobile = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    this.isMobile = event.target.innerWidth <= 991;
  }


  constructor(
    private router: Router,
    private globals: Globals,
    private roleService: RoleService,
    private NewsletterService: NewsletterService,
    private profileservice: ProfileService,
    private AuthService: AuthService
  ) {
    this.user = this.globals.getUser();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        this.navbarOpen = false;
      }
    });
    this.isMobile = window.innerWidth <= 991;
  }
  ngAfterViewInit(): void {
    $(document).on('click', '.nav-item>a', function (e) {
      $(this)
        .parent()
        .toggleClass('menu-open')
        .siblings()
        .removeClass('menu-open');
    });
  }
  ngOnInit(): void {
    this._checkUseRole();
    this._getProfile();
  }
  checkRolePermission: any = [];
  moduleArray: any = [];
  _checkUseRole() {
    let auth = JSON.parse(localStorage.getItem('partner_user') || '{}');
    if (auth.userType === 'PARTNER') {
      return;
    }
    let obj = {
      userId: auth.partnerId,
    };
    this.roleService._getUserRoleByUserId(obj).subscribe((data: any) => {
      this.checkRolePermission = data.data.useCasesList;
      for (let i = 0; i < this.checkRolePermission.length; i++) {
        if (this.checkRolePermission[i].permissionIds.length > 0) {
          this.moduleArray.push(this.checkRolePermission[i].useCaseId);
        }
      }
    });
  }

  SignOut(): void {
    this.AuthService._logout({
      token: this.user?.token,
      hardSignOut: true,
    }).subscribe({
      next: () => {
        this.AuthService.logout();
      },
      error: () => {
        this.AuthService.logout();
      },
    });
  }

  getInitials() {
    if (this.profileData) {
      return (
        (this.profileData.firstName?.toUpperCase()[0] ?? '') +
        (this.profileData.lastName?.toUpperCase()[0] ?? '')
      );
    }
    return '';
  }

  VDATE_TIME_FORMAT = environment.DATE_TIME_FORMAT;

  profileData!: Partner;

  _getProfile() {
    this.profileservice._getProfile().subscribe({
      next: (result: any) => {
        if (result.status == 'SUCCESS') {
          this.profileData = result.data;
          this.user = this.globals.getUser();
          if (this.user) {
            this._getNotificationList();
          }
          setInterval(() => {
            this.user = this.globals.getUser();
            if (this.user) {
              this._getNotificationList();
            }
          }, 60000);
        }
      },
      error: (err: any) => {},
      complete: () => {},
    });
  }

  und = 0;
  cnt = 0;

  _getNotificationList() {
    this.NewsletterService._getNotificationList().subscribe((x) => {
      this.notificationList = x.data.notificationList;
      this.totalNotification = x.data.notificationList.filter(
        (notication) => notication.status === 'SENT'
      ).length;
      let unredcnt: number = parseInt(
        localStorage.getItem('unreadnCnt') ?? '0'
      );

      if (this.totalNotification > unredcnt) {
        this.cnt = this.totalNotification - unredcnt;
        this.totalUnreadNotification = this.cnt;
        this.und = unredcnt + this.cnt;
      } else if (this.totalNotification == unredcnt) {
        this.totalUnreadNotification = 0;
      } else {
        this.totalUnreadNotification = this.totalNotification;
      }
    });
  }
  readNotifications() {
    this.NewsletterService._readNotifications().subscribe({
      next: (result: any) => {
        if (result.status == 'SUCCESS') {
          this.totalUnreadNotification = 0;
        }
      },
      error: (err: any) => {},
      complete: () => {},
    });
  }
}
