import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-menu-icon',
  template: `<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.5 10H17.5M2.5 5H17.5M2.5 15H12.5" [attr.stroke]="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `,
})
export class MenuIcon extends BaseIconComponent {}
